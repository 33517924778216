import { useState } from "react";

const portalId = "";
const formId = "";

// DEMO.
// const portalId = '7608850';
// const formId = '9d615f1c-1892-45fa-90a5-7d3887beb806'

const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;

const useHubspot = () => {
  const [inProgress, setInProgress] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const submit = (fields) => {
    setSubmitted(false);
    setInProgress(true);
    setSuccessMsg("");
    setErrorMsg("");

    const data = { fields, };

    fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json", },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.status === "error") {
          setErrorMsg(data?.message ?? "Error");
          setErrorMsg("Failed");
        } else {
          setSuccessMsg(data?.inlineMessage ?? "Success");
          setSubmitted(true);
        }
      })
      .catch((error) => { console.error(error); })
      .finally(() => { setInProgress(false); });

    // fake submitted
    // setTimeout(() => {
    //   setSubmitted(true);
    //   setInProgress(false);
    //   setSuccessMsg("Success");
    //   console.log(data);
    // }, 2000);
  };

  return { submit, inProgress, successMsg, errorMsg, submitted };
};

export default useHubspot;

// example fields format
// [
//   {
//   //   objectTypeId: "0-1",
//     name: "email",
//     value: email,
//   },
//   {
//   //   objectTypeId: "0-1",
//     name: "firstname",
//     value: "Bahrul",
//   },
//   {
//   //   objectTypeId: "0-1",
//     name: "message",
//     value: "My Message",
//   },
// ]
